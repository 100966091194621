<!--活动详情-->
<template>
  <div class="main-div">
    <!--面包屑-->
    <div class="breadTopDiv">
      <el-row class="breadTop">
        <el-col>
          <top-path-list :path-obj="pathObj"></top-path-list>
        </el-col>
      </el-row>
    </div>
    <!--内容部分-->
    <div class="content-div">
      <el-row>
        <el-col :span="24">
          <div class="w-e-text-container">
            <div v-html="newsData" data-slate-editor></div>
          </div>
        </el-col>
      </el-row>
      <!--10.24需求变动，不展示附件-->
      <!--<el-row>
        <el-col :span="24">
          附件：
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <a href="#" @click="downLoadFile()">1、“山东地标名片”申报表</a>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <a href="#" @click="downLoadFile()">2、山东省地理标志（原产地）优质产品申报表</a>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <a href="#" @click="downLoadFile()">3、山东省地理标志（原产地）优质加工产品申报表</a>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <a href="#" @click="downLoadFile()">4、“山东地标名片”资料提供说明</a>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <a href="#" @click="downLoadFile()">5、“山东省地理标志（原产地）优质产品”（含加工产品）资料提供说明</a>
        </el-col>
      </el-row>-->
      <el-row>
        <div class="applicationBtn">
          <el-button type="primary" @click="toApplication()" v-if="btnShow == 1">立即报名</el-button>
        </div>
      </el-row>
    </div>
    <!--增加取消下方白条-->
    <div style="height: 20px"></div>
  </div>
</template>

<script>
import axios from 'axios'
import {getDetailByd, downLoadFile, formQuery} from '@/utils/commonApi.js'
import topPathList from '@/components/topPathList'
import eventBus from "@/utils/eventBus.js";
export default {
  name: "eventDetail",
  components: { topPathList },
  data() {
    return {
      pathObj: [],
      newsData: '',
      contentId: '',
      categoryId: '',
      btnShow: 0, //立即报名按钮显示与否
    }
  },
  mounted () {
    this.pathObj = this.$route.query.pathObj;
    this.getDetail()
    eventBus.$off('toNewPath')
    eventBus.$on('toNewPath',( title)=>{
      this.toApplication()
    })
  },
  methods: {
    //点击立即报名按钮
    toApplication() {
      if (sessionStorage.getItem("HussarToken")) { //已登录
        let id = this.$route.query.actiId //活动id
        let name = this.$route.query.actiName //活动名称
        //跳转后台活动报名页面
        window.open(process.env.VUE_APP_Back_PATH + '/activitymanage/acti/actiRegistrationForm?id=' + id + '&name=' + name, '_blank')
      } else {//未登录
        //弹出登录弹窗
        eventBus.$emit('toLogin');
      }
    },

    //附件下载
    downLoadFile() {
      let file = {
        fileId: "752554897442086912",
        fileName: "第二届地标名片、优质产品活动评选通知.pdf"
      }
      downLoadFile(file)
    },

    //根据文章id查询文章详情
    getDetail() {
      this.btnShow = this.$route.query.btnShow //判断立即报名按钮是否显示
      let id = this.$route.query.actiId
      let params = {
        id: id,
      }
      formQuery(params).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.newsData = res.data.data.actiContent //内容
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },
  },
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .main-div {
    background-color: #f8f9fa !important;
  }
  .breadTopDiv {
    text-align: center;
    .breadTop {
      width: 1200px;
      margin: auto;
    }
  }
  .content-div {
    min-height: 501px;
    margin: auto;
    padding: 32px 40px 12px 40px;
    width: 1120px;
    background: #FFFFFF;
    a {
      color: #76984e;
    }
  }
  .applicationBtn {
    text-align: center;
    margin-top: 20px;
  }
  ::v-deep .el-row {
    margin: 20px 0;
  }

  @media screen and (max-width: 1366px) {
    .main-div {
      background-color: #f8f9fa !important;
    }
    .breadTopDiv {
      text-align: center;
      .breadTop {
        width: 1200px;
        margin: auto;
      }
    }
    .content-div {
      margin: auto;
      padding: 32px 40px 12px 40px;
      width: 1120px;
      background: #FFFFFF;
      a {
        color: #76984e;
      }
    }
    .applicationBtn {
      text-align: center;
      margin-top: 20px;
    }
    ::v-deep .el-row {
      margin: 20px 0;
    }
  }
</style>
